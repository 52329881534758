import React, { useEffect } from 'react';
import HeroSection from '../../components/HeroSection';
import Section from '../../components/Section';
import { navigate } from 'gatsby';
import BalanceDetails from '../../components/CheckBalance/BalanceDetails';
import webservices from '../../constants/webservices';

const ClientBalance = ({ location }) => {
  useEffect(() => {
    if (!location.state || !location.state.authenticated) {
      navigate('/web-services/login', {
        state: {
          title: webservices.checkBalance.title,
          body: webservices.checkBalance.description,
          redirectOnLogin: webservices.checkBalance.url,
        },
        replace: true,
      });
    }
  }, [location.state]);

  if (!location.state) return null;

  return (
    <>
      <HeroSection />
      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <BalanceDetails />
      </Section>
    </>
  );
};

export default ClientBalance;
