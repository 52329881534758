import React, { useState, useEffect } from 'react';
import Card from '../../components/Card';
import { Heading1 } from '../Typography';
import fetch from '../../utils/fetch';
import BalanceTable from '../CheckBalance/BalanceTable';
import Button from '../Button';
import Link from '../Link';
import Flexbox from '../Flexbox';

const BalanceDetails = () => {
  const [balanceDetails, setBalanceDetails] = useState({});

  useEffect(() => {
    async function fetchDetails() {
      try {
        const response = await fetch.get('/api/user/details');

        const data = response.data.data;

        setBalanceDetails({
          client: data.client,
          name: data.name,
          balance: data.balance,
          balancePaid: data.balance_paid,
          debtType: data.debt_type,
          jacobsRef: data.case_id,
          clientRef: data.client_ref,
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchDetails();
  }, []);

  return (
    <Card p={40} maxWidth='600px' m='0 auto'>
      <Heading1 textAlign='center'>Balance</Heading1>
      <BalanceTable {...balanceDetails} />
      <Flexbox justifyContent='center' mt='20px'>
        <Link href='/' fontSize='16px'>
          Return to Homepage
        </Link>
      </Flexbox>
    </Card>
  );
};

export default BalanceDetails;
